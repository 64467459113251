import axios from "axios";
import { CollectionPreview } from "@/types/FullCollectionType";
import {
  filterSchemaCollections,
  filterSchemaTypeCollections,
  parseAllParams,
  possibleOrderByValuesCollections,
} from "@/lib/validators/queryParamsFiltersTest";
import { CollectionsQueryResult } from "../api/collections/public/route";

export type TpossibleOrderByValuesCollections =
  (typeof possibleOrderByValuesCollections)[number];

export function parseCollectionFilter(
  orderBy: string | undefined,
): orderBy is TpossibleOrderByValuesCollections {
  return possibleOrderByValuesCollections.includes(
    orderBy as TpossibleOrderByValuesCollections,
  );
}

type IGetCollectionsPagination = Pick<
  filterSchemaTypeCollections,
  "page" | "pageSize" | "orderBy" | "order"
>;

export const getPublicCollections = async (
  props: IGetCollectionsPagination,
): Promise<CollectionsQueryResult> => {
  const params = parseAllParams(filterSchemaCollections, props);
  try {
    const { data } = await axios.get<CollectionsQueryResult>(
      `/api/collections/public`,
      { params },
    );
    return data;
  } catch (error) {
    console.error("GET collections/public/route.ts", error);
    return { count: 0, collections: [] };
  }
};

export const getMyCollections = async (
  props: IGetCollectionsPagination,
): Promise<CollectionsQueryResult> => {
  const params = parseAllParams(filterSchemaCollections, props);
  try {
    const { data } = await axios.get<CollectionsQueryResult>(
      `/api/collections/me`,
      { params },
    );

    return data;
  } catch (error) {
    console.error("GET collections/me/route.ts", error);
    return { count: 0, collections: [] };
  }
};
