
Object.defineProperty(exports, "__esModule", { value: true });

const {
  Decimal,
  objectEnumValues,
  makeStrictEnum,
  Public,
  getRuntime,
} = require('./runtime/index-browser.js')


const Prisma = {}

exports.Prisma = Prisma
exports.$Enums = {}

/**
 * Prisma Client JS version: 5.16.1
 * Query Engine version: 34ace0eb2704183d2c05b60b52fba5c43c13f303
 */
Prisma.prismaVersion = {
  client: "5.16.1",
  engine: "34ace0eb2704183d2c05b60b52fba5c43c13f303"
}

Prisma.PrismaClientKnownRequestError = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`PrismaClientKnownRequestError is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)};
Prisma.PrismaClientUnknownRequestError = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`PrismaClientUnknownRequestError is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.PrismaClientRustPanicError = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`PrismaClientRustPanicError is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.PrismaClientInitializationError = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`PrismaClientInitializationError is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.PrismaClientValidationError = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`PrismaClientValidationError is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.NotFoundError = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`NotFoundError is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.Decimal = Decimal

/**
 * Re-export of sql-template-tag
 */
Prisma.sql = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`sqltag is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.empty = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`empty is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.join = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`join is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.raw = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`raw is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.validator = Public.validator

/**
* Extensions
*/
Prisma.getExtensionContext = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`Extensions.getExtensionContext is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.defineExtension = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`Extensions.defineExtension is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}

/**
 * Shorthand utilities for JSON filtering
 */
Prisma.DbNull = objectEnumValues.instances.DbNull
Prisma.JsonNull = objectEnumValues.instances.JsonNull
Prisma.AnyNull = objectEnumValues.instances.AnyNull

Prisma.NullTypes = {
  DbNull: objectEnumValues.classes.DbNull,
  JsonNull: objectEnumValues.classes.JsonNull,
  AnyNull: objectEnumValues.classes.AnyNull
}

/**
 * Enums
 */

exports.Prisma.TransactionIsolationLevel = makeStrictEnum({
  ReadUncommitted: 'ReadUncommitted',
  ReadCommitted: 'ReadCommitted',
  RepeatableRead: 'RepeatableRead',
  Serializable: 'Serializable'
});

exports.Prisma.AccountScalarFieldEnum = {
  id: 'id',
  userId: 'userId',
  type: 'type',
  provider: 'provider',
  providerAccountId: 'providerAccountId',
  refresh_token: 'refresh_token',
  access_token: 'access_token',
  expires_at: 'expires_at',
  token_type: 'token_type',
  scope: 'scope',
  id_token: 'id_token',
  session_state: 'session_state'
};

exports.Prisma.SessionScalarFieldEnum = {
  id: 'id',
  userId: 'userId',
  expires: 'expires',
  sessionToken: 'sessionToken',
  accessToken: 'accessToken',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.UserScalarFieldEnum = {
  id: 'id',
  name: 'name',
  email: 'email',
  emailVerified: 'emailVerified',
  verificationEmailSentAt: 'verificationEmailSentAt',
  passwordResetEmailSentAt: 'passwordResetEmailSentAt',
  image: 'image',
  hashedPassword: 'hashedPassword',
  role: 'role',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  showMovePopup: 'showMovePopup'
};

exports.Prisma.StripeDetailsScalarFieldEnum = {
  id: 'id',
  userId: 'userId',
  stripeCustomerId: 'stripeCustomerId',
  stripeSubscriptionId: 'stripeSubscriptionId',
  stripePriceId: 'stripePriceId',
  stripeCurrentPeriodEnd: 'stripeCurrentPeriodEnd',
  stripeCancelAtPeriodEnd: 'stripeCancelAtPeriodEnd',
  stripeSubscriptionStatus: 'stripeSubscriptionStatus',
  stripeSubscriptionLastPaymentAt: 'stripeSubscriptionLastPaymentAt',
  stripeSubscriptionLastFailedPaymentAt: 'stripeSubscriptionLastFailedPaymentAt',
  billing: 'billing',
  hasSubscribedAtLeastOnce: 'hasSubscribedAtLeastOnce',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.ApiLimitScalarFieldEnum = {
  id: 'id',
  planCredits: 'planCredits',
  freeCredits: 'freeCredits',
  otpCredits: 'otpCredits',
  reset: 'reset',
  userId: 'userId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  receivedFreeCreditsDate: 'receivedFreeCreditsDate',
  receivedPlanCreditsDate: 'receivedPlanCreditsDate'
};

exports.Prisma.BillingDetailsScalarFieldEnum = {
  id: 'id',
  userId: 'userId',
  createdAt: 'createdAt',
  operationKind: 'operationKind',
  creditsAmount: 'creditsAmount',
  creditsKind: 'creditsKind',
  operationId: 'operationId'
};

exports.Prisma.ReservedCreditsScalarFieldEnum = {
  id: 'id',
  userId: 'userId',
  operationId: 'operationId',
  kind: 'kind',
  numCredits: 'numCredits',
  createdAt: 'createdAt'
};

exports.Prisma.VerificationRequestScalarFieldEnum = {
  id: 'id',
  identifier: 'identifier',
  token: 'token',
  expires: 'expires',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.GameScoreScalarFieldEnum = {
  id: 'id',
  fischerScoreTotal: 'fischerScoreTotal',
  fischerScoreAvg: 'fischerScoreAvg',
  ocrAccuracy: 'ocrAccuracy',
  good: 'good',
  total: 'total',
  gameId: 'gameId'
};

exports.Prisma.GameScalarFieldEnum = {
  id: 'id',
  uuid: 'uuid',
  pgn: 'pgn',
  initialPgn: 'initialPgn',
  has_both_players: 'has_both_players',
  images: 'images',
  gameStatus: 'gameStatus',
  humanPgn: 'humanPgn',
  gameDate: 'gameDate',
  name: 'name',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  comments: 'comments',
  userId: 'userId',
  tournamentId: 'tournamentId',
  gameId: 'gameId',
  privacy: 'privacy',
  gameSource: 'gameSource'
};

exports.Prisma.TournamentScalarFieldEnum = {
  id: 'id',
  name: 'name',
  location: 'location',
  startDate: 'startDate',
  endDate: 'endDate',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  userId: 'userId'
};

exports.Prisma.PgnUploadScalarFieldEnum = {
  id: 'id',
  uuid: 'uuid',
  pgn: 'pgn',
  name: 'name',
  tournament: 'tournament',
  date: 'date',
  white: 'white',
  black: 'black',
  images: 'images',
  comments: 'comments',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  userId: 'userId'
};

exports.Prisma.SubscribedEmailScalarFieldEnum = {
  id: 'id',
  email: 'email',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.AttachedPGNScalarFieldEnum = {
  id: 'id',
  event: 'event',
  site: 'site',
  date: 'date',
  white: 'white',
  black: 'black',
  result: 'result',
  whiteElo: 'whiteElo',
  blackElo: 'blackElo',
  timeControl: 'timeControl',
  variant: 'variant',
  ECO: 'ECO',
  gameId: 'gameId',
  blackFideId: 'blackFideId',
  whiteFideId: 'whiteFideId'
};

exports.Prisma.MessageScalarFieldEnum = {
  id: 'id',
  text: 'text',
  isUserMessage: 'isUserMessage',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  userId: 'userId',
  gameId: 'gameId'
};

exports.Prisma.Game_CollectionScalarFieldEnum = {
  id: 'id',
  gameId: 'gameId',
  collectionId: 'collectionId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.CollectionScalarFieldEnum = {
  id: 'id',
  name: 'name',
  description: 'description',
  userId: 'userId',
  privacy: 'privacy',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.ContactUsMessageScalarFieldEnum = {
  id: 'id',
  senderEmail: 'senderEmail',
  subject: 'subject',
  content: 'content',
  createdAt: 'createdAt'
};

exports.Prisma.ReferralScalarFieldEnum = {
  id: 'id',
  userId: 'userId',
  refId: 'refId',
  awardedCredits: 'awardedCredits'
};

exports.Prisma.StripeLogScalarFieldEnum = {
  id: 'id',
  operationIssuer: 'operationIssuer',
  operationType: 'operationType',
  subscriptionId: 'subscriptionId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.BlacklistDomainScalarFieldEnum = {
  id: 'id',
  domain: 'domain',
  enabled: 'enabled'
};

exports.Prisma.BlacklistEmailScalarFieldEnum = {
  id: 'id',
  email: 'email',
  enabled: 'enabled'
};

exports.Prisma.SortOrder = {
  asc: 'asc',
  desc: 'desc'
};

exports.Prisma.NullsOrder = {
  first: 'first',
  last: 'last'
};

exports.Prisma.AccountOrderByRelevanceFieldEnum = {
  id: 'id',
  userId: 'userId',
  type: 'type',
  provider: 'provider',
  providerAccountId: 'providerAccountId',
  refresh_token: 'refresh_token',
  access_token: 'access_token',
  token_type: 'token_type',
  scope: 'scope',
  id_token: 'id_token',
  session_state: 'session_state'
};

exports.Prisma.SessionOrderByRelevanceFieldEnum = {
  id: 'id',
  userId: 'userId',
  sessionToken: 'sessionToken',
  accessToken: 'accessToken'
};

exports.Prisma.UserOrderByRelevanceFieldEnum = {
  id: 'id',
  name: 'name',
  email: 'email',
  image: 'image',
  hashedPassword: 'hashedPassword',
  role: 'role'
};

exports.Prisma.StripeDetailsOrderByRelevanceFieldEnum = {
  id: 'id',
  userId: 'userId',
  stripeCustomerId: 'stripeCustomerId',
  stripeSubscriptionId: 'stripeSubscriptionId',
  stripePriceId: 'stripePriceId',
  stripeSubscriptionStatus: 'stripeSubscriptionStatus'
};

exports.Prisma.ApiLimitOrderByRelevanceFieldEnum = {
  id: 'id',
  userId: 'userId'
};

exports.Prisma.BillingDetailsOrderByRelevanceFieldEnum = {
  id: 'id',
  userId: 'userId',
  operationId: 'operationId'
};

exports.Prisma.ReservedCreditsOrderByRelevanceFieldEnum = {
  id: 'id',
  userId: 'userId',
  operationId: 'operationId'
};

exports.Prisma.VerificationRequestOrderByRelevanceFieldEnum = {
  id: 'id',
  identifier: 'identifier',
  token: 'token'
};

exports.Prisma.GameScoreOrderByRelevanceFieldEnum = {
  id: 'id',
  gameId: 'gameId'
};

exports.Prisma.GameOrderByRelevanceFieldEnum = {
  id: 'id',
  uuid: 'uuid',
  pgn: 'pgn',
  initialPgn: 'initialPgn',
  images: 'images',
  humanPgn: 'humanPgn',
  name: 'name',
  comments: 'comments',
  userId: 'userId',
  tournamentId: 'tournamentId',
  gameId: 'gameId'
};

exports.Prisma.TournamentOrderByRelevanceFieldEnum = {
  id: 'id',
  name: 'name',
  location: 'location',
  userId: 'userId'
};

exports.Prisma.PgnUploadOrderByRelevanceFieldEnum = {
  id: 'id',
  uuid: 'uuid',
  pgn: 'pgn',
  name: 'name',
  tournament: 'tournament',
  white: 'white',
  black: 'black',
  images: 'images',
  comments: 'comments',
  userId: 'userId'
};

exports.Prisma.SubscribedEmailOrderByRelevanceFieldEnum = {
  id: 'id',
  email: 'email'
};

exports.Prisma.AttachedPGNOrderByRelevanceFieldEnum = {
  id: 'id',
  event: 'event',
  site: 'site',
  white: 'white',
  black: 'black',
  result: 'result',
  whiteElo: 'whiteElo',
  blackElo: 'blackElo',
  timeControl: 'timeControl',
  variant: 'variant',
  ECO: 'ECO',
  gameId: 'gameId',
  blackFideId: 'blackFideId',
  whiteFideId: 'whiteFideId'
};

exports.Prisma.MessageOrderByRelevanceFieldEnum = {
  id: 'id',
  text: 'text',
  userId: 'userId',
  gameId: 'gameId'
};

exports.Prisma.Game_CollectionOrderByRelevanceFieldEnum = {
  id: 'id',
  gameId: 'gameId',
  collectionId: 'collectionId'
};

exports.Prisma.CollectionOrderByRelevanceFieldEnum = {
  id: 'id',
  name: 'name',
  description: 'description',
  userId: 'userId'
};

exports.Prisma.ContactUsMessageOrderByRelevanceFieldEnum = {
  id: 'id',
  senderEmail: 'senderEmail',
  subject: 'subject',
  content: 'content'
};

exports.Prisma.ReferralOrderByRelevanceFieldEnum = {
  id: 'id',
  userId: 'userId',
  refId: 'refId',
  awardedCredits: 'awardedCredits'
};

exports.Prisma.StripeLogOrderByRelevanceFieldEnum = {
  id: 'id',
  subscriptionId: 'subscriptionId'
};

exports.Prisma.BlacklistDomainOrderByRelevanceFieldEnum = {
  id: 'id',
  domain: 'domain'
};

exports.Prisma.BlacklistEmailOrderByRelevanceFieldEnum = {
  id: 'id',
  email: 'email'
};
exports.BillingFrequency = exports.$Enums.BillingFrequency = {
  yearly: 'yearly',
  monthly: 'monthly'
};

exports.BillingOperationKind = exports.$Enums.BillingOperationKind = {
  RESERVE_CREDITS: 'RESERVE_CREDITS',
  RETURN_CREDITS: 'RETURN_CREDITS',
  CONSUME_CREDITS: 'CONSUME_CREDITS',
  ISSUE_FREE_CREDITS: 'ISSUE_FREE_CREDITS',
  EXPIRED_FREE_CREDITS: 'EXPIRED_FREE_CREDITS',
  ISSUE_PLAN_CREDITS: 'ISSUE_PLAN_CREDITS',
  EXPIRED_PLAN_CREDITS: 'EXPIRED_PLAN_CREDITS',
  ISSUE_ONE_TIME_PAY_CREDITS: 'ISSUE_ONE_TIME_PAY_CREDITS',
  ISSUE_ONE_TIME_REFERRAL_BONUS_CREDITS: 'ISSUE_ONE_TIME_REFERRAL_BONUS_CREDITS'
};

exports.CreditReservationKind = exports.$Enums.CreditReservationKind = {
  PLAN: 'PLAN',
  FREE: 'FREE',
  ONE_TIME_PAY: 'ONE_TIME_PAY'
};

exports.GameStatus = exports.$Enums.GameStatus = {
  PROCESSING: 'PROCESSING',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED'
};

exports.Privacy = exports.$Enums.Privacy = {
  PUBLIC: 'PUBLIC',
  USERS: 'USERS',
  PRIVATE: 'PRIVATE',
  UNLISTED: 'UNLISTED'
};

exports.GameSource = exports.$Enums.GameSource = {
  IMAGE: 'IMAGE',
  PGN: 'PGN'
};

exports.OperationIssuedBy = exports.$Enums.OperationIssuedBy = {
  USER: 'USER',
  SYSTEM: 'SYSTEM'
};

exports.StripeOperationType = exports.$Enums.StripeOperationType = {
  SUBSCRIPTION_DELETE: 'SUBSCRIPTION_DELETE'
};

exports.Prisma.ModelName = {
  Account: 'Account',
  Session: 'Session',
  User: 'User',
  StripeDetails: 'StripeDetails',
  ApiLimit: 'ApiLimit',
  BillingDetails: 'BillingDetails',
  ReservedCredits: 'ReservedCredits',
  VerificationRequest: 'VerificationRequest',
  GameScore: 'GameScore',
  Game: 'Game',
  Tournament: 'Tournament',
  PgnUpload: 'PgnUpload',
  SubscribedEmail: 'SubscribedEmail',
  AttachedPGN: 'AttachedPGN',
  Message: 'Message',
  Game_Collection: 'Game_Collection',
  Collection: 'Collection',
  ContactUsMessage: 'ContactUsMessage',
  Referral: 'Referral',
  StripeLog: 'StripeLog',
  BlacklistDomain: 'BlacklistDomain',
  BlacklistEmail: 'BlacklistEmail'
};

/**
 * This is a stub Prisma Client that will error at runtime if called.
 */
class PrismaClient {
  constructor() {
    return new Proxy(this, {
      get(target, prop) {
        let message
        const runtime = getRuntime()
        if (runtime.isEdge) {
          message = `PrismaClient is not configured to run in ${runtime.prettyName}. In order to run Prisma Client on edge runtime, either:
- Use Prisma Accelerate: https://pris.ly/d/accelerate
- Use Driver Adapters: https://pris.ly/d/driver-adapters
`;
        } else {
          message = 'PrismaClient is unable to run in this browser environment, or has been bundled for the browser (running in `' + runtime.prettyName + '`).'
        }
        
        message += `
If this is unexpected, please open an issue: https://pris.ly/prisma-prisma-bug-report`

        throw new Error(message)
      }
    })
  }
}

exports.PrismaClient = PrismaClient

Object.assign(exports, Prisma)
